import { render, staticRenderFns } from "./VideoForYou.vue?vue&type=template&id=d5a6588a"
import script from "./VideoForYou.vue?vue&type=script&lang=js"
export * from "./VideoForYou.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {SectionTitle: require('/home/forge/secretcircle.com/components/sectionTitle.vue').default})
